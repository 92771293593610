
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
// import { auth } from '../../helpers/Firebase';
import {
    LOGIN_USER,
    REFRESH_TOKEN,
    LOGOUT_USER,
    loadNews,
    loadUser
} from '../actions';

import {
    loginUserSuccess,
    loginUserError,
    refreshTokenError,
    refreshTokenSuccess,
    logoutUserSuccess,
    logoutUser,
    showLoader,
    hideLoader
} from '../actions';

import {
  loginUserRequest,
  initToken,
  refreshTokenRequest
} from '../../services/api';

import { 
  showError,
  showInfo,
  showSuccess,
  showWarning 
} from '../../services/utils';

function* loginWithEmailPasswordAsync({ payload }) {
    
    const { history, cred, rememberMe } = payload;

    console.log(cred)
    console.log('rem', rememberMe)
    try {
        yield put(showLoader());
        const response = yield call(loginUserRequest, cred);
        console.log(response);
        if(response) {
            yield put(hideLoader());
            yield put(loginUserSuccess(response));
            
            initToken(response.token)
            sessionStorage.setItem('icust_token', response.token);
            sessionStorage.setItem('icust_refresh_token', response.refresh_token);
            sessionStorage.setItem('icust_remember_me', rememberMe.rememberMe);
            yield put(loadUser());
            yield put(loadNews())
            showSuccess('Félicitations!', 'Vous êtes maintenant connectés');
        }

        // if (!loginUser.message) {
        //     localStorage.setItem('user_id', loginUser.user.uid);
        //     yield put(loginUserSuccess(loginUser.user));
        //     history.push('/');
        // } else {
        //     yield put(loginUserError(loginUser.message));
        // }
    } catch (error) {
        console.log(error)
        yield put(hideLoader());
        // yield put(loginUserError(error));
        showError('Désolé!', error.message);

    }
}

function* refreshTokenAsync({ payload }) {
    const { token } = payload;

    console.log(token)
    try {
        const response = yield call(refreshTokenRequest, { refresh_token: token});
        console.log(response);
        if(response) {

            yield put(refreshTokenSuccess(response));
            initToken(response.token)
            sessionStorage.setItem('icust_token', response.token);
            sessionStorage.setItem('icust_refresh_token', response.refresh_token);
            // sessionStorage.setItem('remember_me', rememberMe.rememberMe);
            // showSuccess('Félicitations!', 'Your have succesfully Refreshed your token!');
            yield put(loadUser());
        }
    } catch (error) {
        // console.log(error)
        yield put(logoutUser());
        yield put(refreshTokenError(error));
        showError('Désolé!', error.message);

    }
}

function* logoutUserAsync({ payload }) {

    try {
        yield put(logoutUserSuccess());
        sessionStorage.setItem('icust_token', null);
        sessionStorage.setItem('icust_refresh_token', null);
        sessionStorage.setItem('icust_remember_me', false);    
        localStorage.setItem('icust_user', null);
    } catch (error) {
        console.log(error)

    }
    
    
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPasswordAsync);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logoutUserAsync);
}

export function* watchRefreshToken() {
    yield takeEvery(REFRESH_TOKEN, refreshTokenAsync);
}


export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchRefreshToken),
        fork(watchLogoutUser)
    ]);
}