// import moment from 'moment'
import { Modal } from 'antd';
import moment from 'moment';

export const verifyResponse = (response) => {
    // console.log(response)
    // return response;
    return response.ok ? response : Promise.reject(response);
};

export const handleError = (error) =>
    error.json().then((json) => Promise.reject(json));
// Promise.reject(error);

// Show error modal
export const showError = (title = '', content = '', time = 0) => {
    console.log(title);
    console.log(content);
    console.log(time);
    if (time) {
        let secondsToGo = time;
        const modal = Modal.error({
            title: title,
            centered: true,
            content: `${content} ${secondsToGo} second.`,
        });
        const timer = setInterval(() => {
            secondsToGo -= 1;
            modal.update({
                content: `${content} ${secondsToGo} second.`,
            });
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            modal.destroy();
        }, secondsToGo * 1000);
    } else {
        Modal.error({
            title: title,
            content: content,
        });
    }
};

// Show info modal
export const showInfo = (title = '', content = '', time = 0) => {
    if (time) {
        let secondsToGo = time;
        const modal = Modal.info({
            title: title,
            // centered: true,
            content: `${content} ${secondsToGo} second.`,
        });
        const timer = setInterval(() => {
            secondsToGo -= 1;
            modal.update({
                content: `${content} ${secondsToGo} second.`,
            });
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            modal.destroy();
        }, secondsToGo * 1000);
    } else {
        Modal.info({
            title: title,
            content: content,
        });
    }
};

// Show warning modal
export const showWarning = (title = '', content = '', time = 0) => {
    if (time) {
        let secondsToGo = time;
        const modal = Modal.warning({
            title: title,
            // centered: true,
            content: `${content}`,
        });
        const timer = setInterval(() => {
            secondsToGo -= 1;
            modal.update({
                okText: `${secondsToGo}s  Ok`,
            });
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            modal.destroy();
        }, secondsToGo * 1000);
    } else {
        Modal.warning({
            title: title,
            content: content,
        });
    }
};

// Show success modal
export const showSuccess = (title = '', content = '', time = 0) => {
    if (time) {
        let secondsToGo = time;
        const modal = Modal.success({
            title: title,
            // centered: true,
            content: `${content}`,
        });
        const timer = setInterval(() => {
            secondsToGo -= 1;
            modal.update({
                okText: `${secondsToGo}s  Ok`,
            });
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            modal.destroy();
        }, secondsToGo * 1000);
    } else {
        Modal.success({
            title: title,
            content: content,
        });
    }
};

export const isPublicHoliday = (date) => {
    // console.log(date.format('M'));
    // console.log(date.format('D'));
    let isPublic = false;
    let month = date.format('M');
    let day = date.format('D');

    if (month == 1) {
        if (day == 1) {
            isPublic = true;
        }
    } else if (month == 4) {
        if (day == 13) {
            isPublic = true;
        }
    } else if (month == 5) {
        if (day == 1) {
            isPublic = true;
        } else if (day == 8) {
            isPublic = true;
        } else if (day == 21) {
            isPublic = true;
        }
    } else if (month == 6) {
        if (day == 1) {
            isPublic = true;
        }
    } else if (month == 7) {
        if (day == 14) {
            isPublic = true;
        }
    } else if (month == 8) {
        if (day == 15) {
            isPublic = true;
        }
    } else if (month == 11) {
        if (day == 1) {
            isPublic = true;
        } else if (day == 11) {
            isPublic = true;
        }
    } else if (month == 12) {
        if (day == 25) {
            isPublic = true;
        }
    }

    return isPublic;
};

const y2020 = [
    '1/1/2020',
    '13/4/2020',
    '1/5/2020',
    '8/5/2020',
    '21/5/2020',
    '1/6/2020',
    '14/7/2020',
    '25/12/2020',
    '15/8/2020',
    '1/11/2020',
    '11/11/2020',
];
const y2021 = [
    '1/1/2021',
    '5/4/2021',
    '1/5/2021',
    '8/5/2021',
    '13/5/2021',
    '24/5/2021',
    '14/7/2021',
    '15/8/2021',
    '1/11/2021',
    '11/11/2021',
    '25/12/2021',
];
const m2020 = y2020.map((d) => moment(d, 'DD/MM/YYYY').format('DD/MM/YYYY'));
const m2021 = y2021.map((d) => moment(d, 'DD/MM/YYYY').format('DD/MM/YYYY'));

export const getPublicHolidays = (year, country) => {
    const data = {
        2020: m2020,
        2021: m2021,
    };

    return data[year] || [];
};
