import {
  SHOW_LOADER,
  HIDE_LOADER
} from '../actions';

const INIT_STATE = {
  loading: false,
};

export default (state = INIT_STATE, action) => {
  console.log('globals->reducer.js action', action)
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, loading: true };
    case HIDE_LOADER:
      return { ...state, loading: false };
    default:
      return { ...state };
  }
}